<template>
	<div class="Home" style="min-width: 1900px;">
		<el-container>
			<el-header style="background-color: #FFFFFF;">
				<div class="header">
					<img style="width: 250px;" src="../assets/home/homeLogo.png" />

					<div style="display: flex; width: 200px;justify-content: space-between;">
						<!-- <el-button  class="item1" type="primary" @click="getBack">返回</el-button> -->
					</div>
				</div>
			</el-header>
			<el-main>
				<div class="main">
					<div style="display: flex; justify-content: center; align-items: center; height: 100px;">
						<div style="font-size: 30px; font-weight: bold; ">{{list.name}}</div>
					</div>
					<div style="border-top: 1px dotted #2C3E50;margin: 0 500px;padding-top: 50px;">
						<div style="text-align: center;">(内容预览)</div>
						<div v-for="(v,k) in 5" :key="k">
							<img style="width: 100%;" :src="img_baseurl +'/'+k +'.png'" />
						</div>
						<div style="text-align: center;margin-bottom: 30px;">(预览结束，下载使用请开通VIP会员。)</div>
					</div>
				</div>

			</el-main>
			<el-footer>
				<div class="footer">
					<div style="display: flex; flex-direction: column;">
						<img style="width: 150px;" src="../assets/home/gongzhonghao.jpg" />
						<span style="color: #FFFFFF;">加入智库</span>
					</div>
					<div
						style="display: flex;flex-direction: column; align-items: flex-start; margin-left: 100px; height: 80px;justify-content: space-between;">
						<div class="bottom_flex">
							<img style="width: 30px; margin-right: 20px;" src="../assets/home/danwei.png" />
							<div style="color: #FFFFFF;">主办单位：应急安全智库平台</div>
						</div>
						<div class="bottom_flex">
							<img style="width: 30px; margin-right: 20px;" src="../assets/home/kefu1.png" />
							<div style="color: #FFFFFF;">客服微信：anquan0311</div>
						</div>
					</div>
				</div>
			</el-footer>
		</el-container>
		<el-dialog title="温馨提示(下载超过次数)" :visible.sync="downExceed" width="30%">
			<span>您今天下载资料过于频繁,请明天重试或联系客服。</span>
			<div>
				<img src="../assets/home/kefu.png" />
				<div>
					<span>微信客服</span>
					<div>anquan0311</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		request
	} from "../network/request.js";
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'Home',
		data() {
			return {
				pre_dir: '',
				clientWidth: '',
				list: {},
				downExceed: false,
				content: '',
				buttonShow: true,
				img_baseurl: ''
			}
		},
		created() {
			this.clientWidth = document.documentElement.clientWidth
			this.list = this.imgList
			this.list = JSON.parse(window.localStorage.getItem('imgList'))
			request({
				url: 'datum/cultivate/material/detail',
				method: 'post',
				data: {
					id: this.list.id,
					vip: 0
				}
			}).then(res => {
				this.pre_dir = res.data[0].file.paths
				// this.pre_dir = this.pre_dir.substring(0, this.pre_dir.lastIndexOf('.'))
				this.img_baseurl = 'http://' + "dunanyun.oss-cn-beijing.aliyuncs.com/" + this.pre_dir
			})
		},
		methods: {

		},

	}
</script>

<style lang="less" scoped>
	.header {
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: space-around;
		border-bottom: 1px solid #2C3E50;

	}

	* {
		padding: 0;
	}


	.footer {
		height: 250px;
		margin-left: -1000px;
		background-color: #033B6B;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.popover_flex {
		display: flex;
		align-items: center;

	}

	.main {
		margin-top: 10px;
		min-height: 615px;
	}

	.item1 {
		width: 100px;
		height: 40px;
	}

	.bottom_flex {
		display: flex;
		align-items: center;
	}
</style>
